var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"compose-mail","visible":_vm.shallShowEmailComposeModal,"title":"Compose Email","modal-class":"modal-sticky","footer-class":"d-flex justify-content-between","body-class":"p-0","size":"lg","no-fade":"","hide-backdrop":"","static":"","scrollable":"","no-enforce-focus":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:shall-show-email-compose-modal', val); }},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title",attrs:{"role":"heading"}},[_vm._v(" Compose Mail ")]),_c('div',{staticClass:"modal-actions"},[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){_vm.isContactProgramManager
            ? _vm.onCloseModal()
            : _vm.discardEmail()}}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{attrs:{"variant":"primary","right":""},on:{"click":_vm.sendEmail}},[_c('feather-icon',{staticClass:"mr-1 cursor-pointer",attrs:{"icon":"SendIcon"}}),(_vm.isUpdating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isUpdating)?_c('span',[_vm._v("Sending...")]):_c('span',[_vm._v("Send")])],1)],1)]},proxy:true}])},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendEmail)}}},[(_vm.isPublic)?_c('div',[(!_vm.isContactProgramManager)?_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name: ")]),_c('validation-provider',{ref:"name",attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"id":"name","state":_vm.getValidationState(
                  validationContext
                )},model:{value:(_vm.guestInfo.name),callback:function ($$v) {_vm.$set(_vm.guestInfo, "name", $$v)},expression:"guestInfo.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1):_vm._e(),(!_vm.isContactProgramManager)?_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Phone: ")]),_c('validation-provider',{ref:"phone",staticClass:"w-100",attrs:{"rules":"required","name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"id":"phone","state":_vm.getValidationState(
                  validationContext
                )},model:{value:(_vm.guestInfo.phone),callback:function ($$v) {_vm.$set(_vm.guestInfo, "phone", $$v)},expression:"guestInfo.phone"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1):_vm._e(),(!_vm.isContactProgramManager)?_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email: ")]),_c('validation-provider',{ref:"email",staticClass:"w-100",attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"id":"email","state":_vm.getValidationState(
                  validationContext
                )},model:{value:(_vm.guestInfo.email),callback:function ($$v) {_vm.$set(_vm.guestInfo, "email", $$v)},expression:"guestInfo.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                  validationContext
                )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1):_vm._e()]):_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{staticClass:"form-label",attrs:{"for":"email-to"}},[_vm._v("To: ")]),_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}}),_c('validation-provider',{staticClass:"flex-fill flex-grow-2 flex-2 email-to-selector",attrs:{"vid":"email-to","rules":{ required: true },"name":"User"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-to","loading":_vm.isLoading,"calculate-position":_vm.withPopper,"append-to-body":"","placeholder":"Select a User","state":_vm.getValidationState(
                validationContext
              )},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var avatar = ref.avatar;
              var name = ref.name;
              var role = ref.role;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"2"}},[_c('b-avatar',{staticClass:"mr-1",attrs:{"size":"md","src":avatar,"variant":avatar? 'light': 'secondary',"text":("" + (name.charAt(0)))}})],1),_c('b-col',{attrs:{"sm":"9"}},[_c('span',{staticClass:"mr-1 ml-n3"},[_vm._v(" "+_vm._s(name))]),(role !== undefined)?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(role)+" ")]):_vm._e()],1)],1)]}},{key:"selected-option",fn:function(ref){
              var avatar = ref.avatar;
              var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","variant":avatar? 'light': 'secondary',"src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.user),callback:function ($$v) {_vm.$set(_vm.composeData, "user", $$v)},expression:"composeData.user"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCcField),expression:"showCcField"}],staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"email-cc"}},[_vm._v("CC: ")]),_c('v-select',{staticClass:"flex-grow-1 email-cc-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-cc"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var avatar = ref.avatar;
              var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
              var avatar = ref.avatar;
              var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.cc),callback:function ($$v) {_vm.$set(_vm.composeData, "cc", $$v)},expression:"composeData.cc"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBccField),expression:"showBccField"}],staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"email-bcc"}},[_vm._v("Bcc ")]),_c('v-select',{staticClass:"flex-grow-1 email-bcc-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","close-on-select":false,"options":_vm.emailToOptions,"input-id":"email-bcc"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var avatar = ref.avatar;
              var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
              var avatar = ref.avatar;
              var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.composeData.bcc),callback:function ($$v) {_vm.$set(_vm.composeData, "bcc", $$v)},expression:"composeData.bcc"}})],1),_c('div',{staticClass:"compose-mail-form-field justify-content-start"},[_c('label',{attrs:{"for":"email-subject"}},[_vm._v("Subject: ")]),_c('validation-provider',{ref:"emailSubject",staticClass:"w-100",attrs:{"rules":"required","name":"Email Subject"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"id":"email-subject","state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.composeData.subject),callback:function ($$v) {_vm.$set(_vm.composeData, "subject", $$v)},expression:"composeData.subject"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"message-editor"},[_c('validation-provider',{ref:"emailBody",attrs:{"rules":"required","vid":"emailBody","name":"Email Body"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [(_vm.shallShowEmailComposeModal)?_c('aom-text-editor',{attrs:{"min-height":300,"tool-bar":_vm.toolBar,"state":_vm.getValidationState(
                validationContext
              )},model:{value:(_vm.composeData.body),callback:function ($$v) {_vm.$set(_vm.composeData, "body", $$v)},expression:"composeData.body"}}):_vm._e(),_c('b-form-invalid-feedback',{staticClass:"p-50",attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
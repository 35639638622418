<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">
      <template>
        <div
          class="d-lg-none d-flex align-items-center justify-content-start bg-white p-1"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
        <b-card>
          <vue-good-table
            mode="remote"
            :is-loading="isLoading"
            :columns="columns"
            :rows="rows"
            :rtl="directionIsRTL"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: perPage,
            }"
            style-class="vgt-table striped"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Common -->
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <table-pagination
                :per-page="perPage"
                :total="total"
                @perPageChanged="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
                @pageChanged="
                  (value) => props.pageChanged({ currentPage: value })
                "
              />
            </template>
          </vue-good-table>
        </b-card>
      </template>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
    <!-- Compose Email Modal -->
    <email-compose
      v-model="shallShowEmailComposeModal"
      :is-program-communications="true"
      @emailAdded="fetchProgramEmails"
    />
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { formatDateToMonthShort } from "@core/utils/filter";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import EmailLeftSidebar from "./EmailLeftSidebar.vue";
import { commsService } from "@services";
import { makeErrorToast } from "@/libs/utils";
import { recipientTypes } from "@models";
import EmailCompose from "./EmailCompose.vue";
import { VBToggle, BCard } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import {
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { localeDateStringFromIsoDateTime } from "@/libs/utils";
import { isPublicOptions, hasDetailPageOptions } from "@/models/eKeyDates";

export default {
  components: {
    EmailLeftSidebar,
    EmailCompose,
    VueGoodTable,
    TablePagination,
    BCard,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      element: {},
      perPage: 10,
      page: 1,
      dir: false,

      columns: [
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
          thClass: 'vgt-left-align',
          tdClass: "vgt-left-align",
          width: "12em",
        },
        {
          label: "Recipient",
          field: "recipient",
          filterOptions: {
            enabled: true,
            placeholder: "Search recipient",
          },
          width: "5rem",
        },
        {
          label: "Sender",
          field: "sender",
          filterOptions: {
            enabled: true,
            placeholder: "Search sender",
          },
          width: "5rem",
        },
        {
          label: "Subject",
          field: "subject",
          filterOptions: {
            enabled: true,
            placeholder: "Search subject",
          },
          width: "12rem",
        },
      ],
      columnFilters: [],
      sort: [],
      total: 0,
      rows: [],
      searchTerm: "",
      isPublicOptions,
      hasDetailPageOptions,
    };
  },
  computed: {
    profile() {
      return this.$store.getters["profile/profile"];
    },
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    appCurrentProgram() {
      return this.$store.getters["app/currentProgram"];
    },
  },
  created() {
    this.loadItems();
  },
  mounted() {
    const inputs = document.getElementsByName("vgt-date");
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
      });
    });
  },
  methods: {
    async fetchProgramEmails(searchQuery) {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await commsService.getProgramsComms(programId, searchQuery);
        const {items, total} = response.data;
        this.emailList = items.sort(function compare(a, b) {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        this.emailsTotal = total;
      } catch (e) {
        this.$toast(makeErrorToast("Program emails not loaded"));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== "none") {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["date", "recipient", "sender", "subject"]) {
        if (params.columnFilters[col]) {
          columnFilters.push({
            field: col,
            value: params.columnFilters[col],
          });
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const programId = this.appCurrentProgram.id;
        const response = await commsService.getProgramUndeliverableMessages(
          programId,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          date: localeDateStringFromIsoDateTime(item.created_at, true),
          recipient: item.recipient_email ?? "",
          sender: item.sender_email,
          subject: item.subject,
        }));
      } catch (e) {
        this.$toast(makeErrorToast("Undelivered messages not loaded."));
        this.$log.error(e);
      }
      this.isLoading = false;
    },

    formatDate(date) {
      return localeDateStringFromIsoDateTime(date);
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };
    // Compose
    const shallShowEmailComposeModal = ref(false);

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      perfectScrollbarSettings,
      formatDateToMonthShort,
      shallShowEmailComposeModal,
      mqShallShowLeftSidebar,
      recipientTypes,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
</style>

<style lang="scss" scoped>
.badge {
  font-weight: 400;
}
table.vgt-table td {
  vertical-align: middle;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
